@media (max-width: 1280px) {
  html {
    font-size: 12px;
  }
}

@media ((min-width: 1281px) and  (max-width: 1535px)) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 1536px) {
  html {
    font-size: 16px;
  }
}
